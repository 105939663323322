<template>
  <header class="home-header">
    <nav class="home-header-nav" ref="navRef">
      <div class="home-header-nav-inner ec-container">
        <h1 title="人翼咨询" class="home-header-logo">
          <img src="@/assets/images/logo.png" alt="人翼咨询" />
        </h1>
        <ul class="home-header-nav-list">
          <li
            class="home-header-nav-item"
            :class="active === item.key ? 'active' : ''"
            v-for="item in navs"
            :key="item.key"
            @click="onMenu(item)"
          >
            <a href="javascript: void(0);" @click="onNav(item)">
              {{ item.na }}
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <div class="home-header-content ec-container">
      <h2 class="home-header-title">致力于企业HR管理咨询</h2>
      <h3 class="home-header-title-sub">赋能十万家产业， 成就百万行业人才</h3>
      <EcButton class="home-header-btn" @click="onMenu(navs[4])">
        联系我们
      </EcButton>
    </div>
  </header>
</template>

<script>
import EcButton from "@/components/Button.vue";
import { onMounted, onUnmounted, ref } from "vue";
import { heightToTop } from "@/utils";

const navs = [
  { key: "1", na: "服务内容", top: 0 },
  { key: "2", na: "解决方案", id: "homeSolution" },
  { key: "3", na: "关于我们", id: "homeAbout" },
  { key: "4", na: "服务客户", id: "homeCustomer" },
  { key: "5", na: "联系我们", id: "homeContact" },
];

export default {
  name: "HomeHeader",
  components: {
    EcButton,
  },
  setup() {
    const active = ref(navs[0].key);
    const navRef = ref(null);

    const onNav = ({ key }) => {
      active.value = key;
    };

    const onScroll = () => {
      const sTop = document.body.scrollTop + document.documentElement.scrollTop;
      if (!navRef.value) return;
      if (sTop > 129) {
        navRef.value.style.cssText = `background:#ffffff;box-shadow:0 2px 4px 0 rgba(104, 119, 174, 0.08);`;
        return;
      }
      navRef.value.style.cssText = "";
    };

    const onMenu = (item) => {
      if (item.top !== void 0) {
        document.documentElement.scrollTop = item.top;
        return;
      }
      if (item.id) {
        const ele = document.getElementById(item.id);
        if (!ele) return;
        const height = heightToTop(ele);
        document.documentElement.scrollTop = height - 129;
      }
    };

    onMounted(() => {
      onScroll();
      document.addEventListener("scroll", onScroll);
    });

    onUnmounted(() => {
      document.removeEventListener("scroll", onScroll);
    });

    return {
      navs,
      active,
      onNav,
      navRef,
      onMenu,
    };
  },
};
</script>

<style lang="less" scoped>
.home-header {
  width: 100%;
  height: 605px;
  background: rgba(@ec-color-primary, 0.2)
    url("@/assets/images/home/banner.png") no-repeat center / cover;
  min-width: max-content;

  &-nav {
    position: fixed;
    width: 100%;
    height: 129px;
    top: 0;
    z-index: 2;
    padding: 36px 0;
    transition: all 0.3s linear;
  }
  &-nav-inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &-logo {
    height: 36px;

    img {
      display: block;
      outline: none;
      border: 0;
      height: 100%;
    }
  }
  &-nav-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding-top: 6px;
  }
  &-nav-item {
    font-size: 16px;
    font-weight: 400;
    position: relative;
    padding: 13px 55px;

    &::after {
      content: " ";
      display: none;
      position: absolute;
      z-index: 1;
      width: 30px;
      height: 4px;
      border-radius: 2px;
      background: @ec-color-primary;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    a {
      color: #525252;
      font-weight: 500;
    }

    &:hover,
    &.active {
      &::after {
        display: block;
        animation-fill-mode: both;
        animation-duration: 300ms;
        animation-name: fadeInUp;
      }
    }
  }
  &-title {
    font-size: 38px;
    font-weight: 500;
    color: #2f2f2f;
  }
  &-content {
    padding-top: 238px;
  }
  &-title-sub {
    font-size: 20px;
    font-weight: normal;
    color: #525252;
    margin-top: 48px;
  }
  &-btn {
    margin-top: 48px;
  }
}
</style>
