<template>
  <li class="home-service-features">
    <h3 class="home-service-features-title">
      {{ title }}
    </h3>
    <ul class="home-service-features-content">
      <li class="home-service-feature" v-for="item in list" :key="item.key">
        {{ item.na }}
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  name: "HomeFeature",
  props: {
    title: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.home-service-features {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &-title {
    color: @ec-color-primary;
    font-size: 20px;
    font-weight: bold;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    list-style: none;
    margin-top: 36px;
    gap: 20px;
  }

  .home-service-feature {
    font-size: 14px;
    font-weight: 400;
    color: #525966;
    padding-left: 17px;
    position: relative;

    &::before {
      content: " ";
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: @ec-color-primary;
      position: absolute;
      z-index: 1;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
