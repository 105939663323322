<template>
  <router-view />
</template>

<script>
import AOS from "aos";
import { getScrollTop } from "@/utils";

export default {
  name: "App",
  data() {
    return {
      scrollRef: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      this.$store.commit("setScrollTop", getScrollTop());
      // increase value up to 10, then refresh AOS
      this.scrollRef <= 10 ? this.scrollRef++ : AOS.refresh();
    },
  },
};
</script>

<style lang="less">
#app {
}
</style>
