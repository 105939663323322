import { createStore } from "vuex";
import { getScrollTop } from "@/utils";

export function initStore(config) {
  return createStore({
    state: {
      scrollTop: getScrollTop(),
      config,
    },
    getters: {},
    mutations: {
      setScrollTop(state, scrollTop) {
        state.scrollTop = scrollTop;
      },
    },
    actions: {},
    modules: {},
  });
}
