<template>
  <div class="home-service">
    <div class="ec-container home-service-inner">
      <h2 class="home-service-title">服务内容</h2>
      <ul class="home-service-content">
        <li class="home-service-content-item features">
          <ul class="home-service-feature-list">
            <HomeFeature
              v-for="item in featureList.left"
              :key="item.key"
              :title="item.title"
              :list="item.list"
            />
          </ul>
        </li>
        <li class="home-service-content-item banner">
          <img src="@/assets/images/home/scenes.png" alt="" />
        </li>
        <li class="home-service-content-item features">
          <ul class="home-service-feature-list">
            <HomeFeature
              v-for="item in featureList.right"
              :key="item.key"
              :title="item.title"
              :list="item.list"
            />
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import HomeFeature from "./components/Feature.vue";

export default {
  name: "HomeService",
  components: {
    HomeFeature,
  },
  data() {
    return {
      featureList: {
        left: [
          {
            key: "1",
            title: "组织与职位体系",
            list: [
              { key: "11", na: "管控体系设计" },
              { key: "12", na: "组织结构设计与优化" },
              { key: "13", na: "部门与职位权责梳理" },
              { key: "14", na: "岗位价值评估" },
              { key: "15", na: "职级体系设计" },
            ],
          },
          {
            key: "2",
            title: "绩效与激励体系",
            list: [
              { key: "21", na: "战略解码" },
              { key: "22", na: "绩效体系设计" },
              { key: "23", na: "绩效辅导与绩效沟通" },
              { key: "24", na: "阿米巴经营与激励" },
              { key: "25", na: "中长期激励计划" },
            ],
          },
        ],
        right: [
          {
            key: "3",
            title: "薪酬体系",
            list: [
              { key: "31", na: "薪酬对标分析" },
              { key: "32", na: "薪酬调整建议" },
              { key: "33", na: "工资总额管控" },
              { key: "34", na: "薪酬体系设计" },
              { key: "35", na: "薪酬与绩效挂钩设计" },
            ],
          },
          {
            key: "4",
            title: "人力资本数据",
            list: [
              { key: "41", na: "管控体系设计" },
              { key: "42", na: "组织结构设计与优化" },
              { key: "43", na: "部门与职位权责梳理" },
              { key: "44", na: "岗位价值评估" },
              { key: "45", na: "职级体系设计" },
            ],
          },
        ],
      },
    };
  },
};
</script>

<style lang="less" scoped>
.home-service {
  &-inner {
    padding-top: 74px;
    padding-bottom: 87px;
  }
  &-title {
    text-align: center;
    font-size: 36px;
    font-weight: 400;
    color: #2f2f2f;
  }
  &-content {
    margin-top: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 130px;
  }
  &-content-item {
    flex-shrink: 0;
    flex-grow: 0;

    &.features {
    }
    &.banner {
      width: 543px;
    }
  }
  &-feature-list {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 147px;
    flex-direction: column;
    list-style: none;
  }
}
</style>
