import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { initStore } from "./store";
import AOS from "aos";

AOS.init();

import "./assets/iconfonts/iconfont.css";
import "aos/dist/aos.css";
import "ant-design-vue/dist/antd.less";
import "./styles/reboot.less";

import { Form, Input, message, Row, Col } from "ant-design-vue";

const app = createApp(App);

app.use(Form);
app.use(Input);
app.use(Row);
app.use(Col);

app.use(router);
app.use(AOS);

app.config.globalProperties.$message = message;

// 初始化加载应用配置
fetch("/app.json")
  .then((res) => res.json())
  .then((config) => {
    const store = initStore(config);
    app.use(store);

    app.mount("#app");
  });
