<template>
  <div class="home-contact" id="homeContact">
    <div class="ec-container home-contact-inner">
      <div class="home-contact-map">
        <div class="baidu-map" id="baiduMap"></div>
      </div>
      <div class="home-contact-form">
        <a-form
          ref="formRef"
          :model="formState"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row class="home-contact-form-row" :gutter="15">
            <a-col :span="12" class="home-contact-form-col">
              <a-form-item ref="name" label="" name="name">
                <a-input
                  autocomplete="off"
                  placeholder="您的姓名"
                  v-model:value="formState.name"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12" class="home-contact-form-col">
              <a-form-item ref="company" label="" name="company">
                <a-input
                  autocomplete="off"
                  placeholder="您的公司"
                  v-model:value="formState.company"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item ref="phone" label="" name="phone">
            <a-input
              autocomplete="off"
              placeholder="您的电话"
              v-model:value="formState.phone"
            />
          </a-form-item>
          <a-form-item ref="content" label="" name="content">
            <a-textarea
              autocomplete="off"
              placeholder="您的留言内容..."
              v-model:value="formState.content"
            />
          </a-form-item>
          <a-form-item ref="code" label="" name="code">
            <div class="home-contact-input-addon">
              <a-input
                autocomplete="off"
                placeholder="验证码"
                v-model:value="formState.code"
              />
              <a
                href="javascript: void(0);"
                class="home-contact-code"
                @click="getCodeImg"
              >
                <img :src="codeImg" alt="" />
              </a>
            </div>
          </a-form-item>
          <EcButton class="home-contact-btn" @click="onSubmit">提 交</EcButton>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, toRaw } from "vue";
import EcButton from "@/components/Button.vue";
import { showToast } from "@/utils";
import { useStore } from "vuex";
import { getImageCode, submitContact } from "@/apis";

export default {
  name: "HomeContact",
  components: {
    EcButton,
  },
  setup() {
    const formRef = ref();
    const formState = reactive({
      name: "",
      company: "",
      phone: "",
      content: "",
      code: "",
    });
    const rules = {
      name: [
        {
          required: true,
          message: "请输入您的姓名",
          trigger: "blur",
        },
        {
          min: 1,
          max: 10,
          message: "姓名长度为1-10个字符",
          trigger: "blur",
        },
      ],
      company: [
        {
          required: true,
          message: "请输入您的公司名称",
          trigger: "blur",
        },
        {
          min: 1,
          max: 100,
          message: "公司名称长度为1-100个字符",
          trigger: "blur",
        },
      ],
      phone: [
        {
          required: true,
          message: "请输入您的电话",
          trigger: "blur",
        },
        {
          min: 1,
          max: 20,
          message: "电话长度为1-20个字符",
          trigger: "blur",
        },
      ],
      content: [
        {
          required: true,
          message: "请输入您的留言内容",
          trigger: "blur",
        },
        {
          min: 1,
          max: 500,
          message: "留言内容长度为1-500个字符",
          trigger: "blur",
        },
      ],
      code: [
        {
          required: true,
          message: "请输入验证码",
          trigger: "blur",
        },
        {
          min: 4,
          max: 4,
          message: "验证码为4个字符",
          trigger: "blur",
        },
      ],
    };
    const store = useStore();
    const codeImg = ref();
    const loading = ref(false);

    const appConfig = computed(() => store.state.config);

    const onSubmit = () => {
      if (loading.value) return;
      loading.value = true;
      formRef.value
        .validate()
        .then(async () => {
          console.log("values", formState, toRaw(formState));
          const data = toRaw(formState);
          const res = await submitContact({
            appId: appConfig.value.appId,
            name: data.name,
            contact: data.phone,
            company: data.company,
            remark: data.content,
            code: data.code,
          });
          if (res.code !== 0) {
            showToast(res);
            loading.value = false;
            return;
          }
          await showToast({ code: 0, msg: "提交成功!" });
          loading.value = false;
          window.history.go(0);
        })
        .catch((error) => {
          console.log("error", error);
          loading.value = false;
        });
    };

    const resetForm = () => {
      formRef.value.resetFields();
    };

    const getCodeImg = async () => {
      if (loading.value) return;
      loading.value = true;
      const res = await getImageCode({
        width: 126,
        height: 57,
        appId: appConfig.value.appId,
      });
      if (res.code !== 0) {
        showToast(res);
        loading.value = false;
        return;
      }
      codeImg.value = res.data;
      loading.value = false;
    };

    const init = () => {
      getCodeImg();
      const BaiduMap = window.BMapGL;
      const map = new BaiduMap.Map("baiduMap");
      const point = new BaiduMap.Point(116.457171, 39.911248);
      // 地点坐标
      map.centerAndZoom(point, 15);
      // 创建点标记
      const marker = new BaiduMap.Marker(point);
      map.addOverlay(marker);
      // 创建文本标注对象
      const opts = {
        position: point,
        offset: new BaiduMap.Size(-80, -80),
      };
      const label = new BaiduMap.Label("通用国际中心A座1606", opts);
      label.setStyle({
        color: "#2667F5",
        borderRadius: "5px",
        borderColor: "#cccccc",
        padding: "10px",
        fontSize: "16px",
      });
      map.addOverlay(label);
      // 开启鼠标滚轮缩放
      map.enableScrollWheelZoom(true);
      // 添加比例尺控件
      const scaleCtrl = new BaiduMap.ScaleControl();
      map.addControl(scaleCtrl);
      // 添加缩放控件
      const zoomCtrl = new BaiduMap.ZoomControl();
      map.addControl(zoomCtrl);
    };

    onMounted(() => {
      init();
    });

    return {
      formRef,
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
      other: "",
      formState,
      rules,
      onSubmit,
      resetForm,
      codeImg,
    };
  },
};
</script>

<style lang="less" scoped>
.home-contact {
  background: @ec-color-primary url("@/assets/images/home/contact-bg.png")
    no-repeat left top / cover;
  min-width: max-content;

  .baidu-map {
    width: 554px;
    height: 294px;
  }

  &-inner {
    position: relative;
    padding-top: 69px;
    padding-bottom: 77px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &-map {
    margin-left: 32px;
  }
  &-form {
    position: absolute;
    z-index: 1;
    top: 63px;
    right: 1px;
    background: #ffffff;
    border-radius: 20px;
    border: 1px solid #eff2f5;
    box-shadow: 10px 13px 25px 2px rgba(104, 119, 174, 0.08);
    width: 654px;
    height: 548px;
    padding: 53px 53px 62px;

    :deep(.ant-form) {
      .ant-form-item {
      }
      .ant-form-item-control {
        width: 100%;
        max-width: 100%;

        input {
          height: 59px;
          line-height: 59px;
          width: 100%;
          padding: 0 25px;
          font-size: 14px;
          font-weight: 400;
        }
        textarea {
          height: 109px;
          resize: none;
        }
      }
    }
  }
  &-btn {
    margin-top: 11px;
    width: 100%;
    border-radius: 0;
  }

  &-input-addon {
    position: relative;

    :deep(input.ant-input) {
      padding-right: 153px !important;
    }

    .home-contact-code {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 1px;
      width: 128px;
      height: 100%;
    }
  }
}
</style>
