import { request } from "@/utils";

export function getImageCode(params) {
  const { width, height } = params;
  return request({
    url: `/api/data/consulting/code/image/get/${width}/${height}`,
    method: "GET",
    params,
  });
}

/**
 * 提交接口
 * @param params
 * @param {string} params.appId
 * @param {string} params.name
 * @param {string} params.company
 * @param {string} params.contact
 * @param {string} params.remark
 * @param {string} params.code
 * @returns {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export function submitContact(params) {
  return request({
    url: `/api/data/consulting/add`,
    method: "POST",
    params,
  });
}
