export function getScrollTop() {
  return document.documentElement.scrollTop || document.body.scrollTop;
}

export function getType(val) {
  return Object.prototype.toString.call(val).slice(8, -1).toLowerCase();
}

export function parseGetUrl(url, data) {
  if (!data) return url;
  // check url has ?
  const parseUrl = () => {
    if (/\?/.test(url)) {
      // is end of ?
      if (/\?$/.test(url)) {
        return url;
      }
      return url + "&";
    }
    return url + "?";
  };

  url = parseUrl();

  if (getType(data) === "String") return url + data;
  let str = "";
  const values = Object.values(data);
  Object.keys(data).forEach((key, idx) => {
    let val = values[idx];
    if (val == null) val = "";
    str += `${key}=${"" + val}&`;
  });
  str = str.replace(/&$/, "");
  return url + str;
}

export function heightToTop(ele) {
  let root = document.body;
  let height = 0;
  do {
    height += ele.offsetTop;
    ele = ele.offsetParent;
  } while (ele !== root);
  return height;
}
