<template>
  <div class="home-customer" id="homeCustomer">
    <div class="ec-container home-customer-inner">
      <h2 class="home-customer-title">服务客户</h2>
      <div class="home-customer-content">
        <img src="@/assets/images/home/serve-logo.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeCustomer",
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.home-customer {
  background: #f6f7fc;
  min-width: max-content;

  &-inner {
    padding-top: 67px;
    padding-bottom: 120px;
  }
  &-title {
    font-size: 36px;
    text-align: center;
    font-weight: 400;
    color: #2f2f2f;
  }
  &-content {
    margin-top: 54px;

    img {
      width: 100%;
      min-height: 300px;
      border: 0;
    }
  }
}
</style>
