<template>
  <div class="home-solution" id="homeSolution">
    <div class="ec-container home-solution-inner">
      <h2 class="home-solution-title">解决方案</h2>
      <ul class="home-solution-content">
        <li
          class="home-solution-item"
          :class="item.key === active ? 'active' : ''"
          v-for="item in list"
          :key="item.key"
          @click="onActive(item)"
        >
          <i
            class="home-solution-item-icon ryzx-icon"
            :class="
              item.key === active
                ? `ryzx-icon-${item.active}`
                : `ryzx-icon-${item.icon}`
            "
          />
          <h3 class="home-solution-item-title">{{ item.title }}</h3>
          <p class="home-solution-item-content">
            {{ item.msg }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

const list = [
  {
    key: "1",
    active: "soluIcoa2",
    icon: "soluIcoa1",
    title: "人力资本大数据",
    msg: "基于人本大数据平台，为企业提供各类人力资源决策信息支持。",
  },
  {
    key: "2",
    active: "soluIcob2",
    icon: "soluIcob1",
    title: "组织与职位体系咨询",
    msg: "组织架构与职位体系的梳理、优化与设计，助力企业组织效能提升。",
  },
  {
    key: "3",
    active: "soluIcoc2",
    icon: "soluIcoc1",
    title: "薪酬体系咨询",
    msg: "以薪酬大数据为基础，以全新的全面激励框架为企业设计适应其管理体系、文化特点的薪酬体系，全面提升薪酬激励性和企业人才竞争力。",
  },
  {
    key: "4",
    active: "soluIcoe2",
    icon: "soluIcoe1",
    title: "绩效体系咨询",
    msg: "以战略落地为导向，结合战略解码及组织的绩效成熟度设计能够支撑战略目标实现的绩效方案。",
  },
  {
    key: "5",
    active: "soluIcof2",
    icon: "soluIcof1",
    title: "中长期激励咨询",
    msg: "设计贴合企业战略目标的中长期激励机制，保留与激励核心骨干，为战略目标达成奠定长效基础。",
  },
  {
    key: "6",
    active: "soluIcog2",
    icon: "soluIcog1",
    title: "人才管理咨询",
    msg: "通过人才发展体系的搭建，为企业揽才、用才、育才，不断提升人力资本价值提供机制与指引。",
  },
];

export default {
  name: "HomeSolution",
  setup() {
    const active = ref(list[0].key);

    const onActive = ({ key }) => {
      active.value = key;
    };

    return {
      active,
      list,
      onActive,
    };
  },
};
</script>

<style lang="less" scoped>
.home-solution {
  background: @ec-color-primary url("@/assets/images/home/solution-bg.png")
    no-repeat center / cover;
  min-width: max-content;

  &-inner {
    padding-top: 57px;
    padding-bottom: 63px;
  }
  &-title {
    font-size: 36px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
  }
  &-content {
    margin-top: 64px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    flex-wrap: wrap;
  }
  &-item {
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 8px;
    width: 406px;
    height: 280px;
    margin-bottom: 22px;
    background: rgba(255, 255, 255, 0.1);
    padding: 40px 53px 27px;
    transition: all 0.3s linear;

    &.active {
      background: #ffffff;

      .home-solution-item-title {
        color: #3a3b3d;
      }

      .home-solution-item-content {
        color: #616366;
      }
    }
  }
  &-item-icon {
    font-size: 34px;
    color: #ffffff;
  }
  &-item-title {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    margin-top: 5px;
    cursor: default;
  }
  &-item-content {
    margin-top: 30px;
    font-size: 14px;
    font-weight: 400;
    color: #d0d7ff;
    line-height: 34px;
    cursor: default;
  }
}
</style>
