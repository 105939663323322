import { parseGetUrl } from "./tools";
import { FETCH_CONF } from "@/configs";

/**
 * 异步请求
 * @param {Object} options
 * @param {string} options.url 请求地址
 * @param {{[k:string]:any}} options.params 请求参数
 * @param {string} options.method 请求类型
 * @return {Promise<{code:0|1|2,msg:string,data:any}>}
 */
export function request(options) {
  const merged = {
    params: {},
    method: "POST",
    timeout: 10000,
    ...options,
  };
  const { url, params, method, timeout } = merged;
  const response = { code: 0, msg: "succeed" };
  const controller = new AbortController();
  const signal = controller.signal;

  const timer = setTimeout(() => {
    controller.abort();
  }, timeout);

  return new Promise((resolve) => {
    if (!url) {
      response.code = 2;
      response.msg = "url is required";
      resolve(response);
      console.error("url is required");
      return;
    }

    let requestUrl = url; // .replace(/^\//, "");
    const requestMethod = method.toUpperCase();
    if (requestMethod.toUpperCase() === "GET") {
      const getParams = { v: +new Date(), ...params };
      requestUrl = parseGetUrl(requestUrl, {
        ...getParams,
      });
    }
    let requestBody = undefined;
    if (requestMethod.toUpperCase() === "POST") {
      requestBody = JSON.stringify(params);
    }

    fetch(`${requestUrl}`, {
      signal,
      headers: {
        "Content-Type": "application/json; utf-8",
        Session: FETCH_CONF.Session,
      },
      mode: "cors",
      credentials: "include",
      method: requestMethod,
      body: requestBody,
    })
      .then((res) => {
        const Session = res.headers.get("Session");
        if (Session) {
          FETCH_CONF.Session = Session;
        }
        return res.json();
      })
      .then((res) => {
        if (res.code === 200) {
          response.data = res.data;
          resolve(response);
          return;
        }
        response.code = 2;
        response.msg = res.msg;
        resolve(response);
      })
      .catch((err) => {
        response.code = 2;
        response.msg = err.message;
        resolve(response);
      })
      .finally(() => {
        clearTimeout(timer);
      });
  });
}
