<template>
  <div class="home-index">
    <Header />
    <Service />
    <Solution />
    <About />
    <Customer />
    <Contact />
    <Footer />
  </div>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import About from "./About.vue";
import Contact from "./Contact.vue";
import Customer from "./Customer.vue";
import Service from "./Service.vue";
import Solution from "./Solution.vue";

export default {
  name: "HomeIndex",
  components: {
    Solution,
    Service,
    Customer,
    Contact,
    About,
    Footer,
    Header,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.home-index {
  position: relative;
  min-height: 100vh;

  &-bg {
    background-color: #fafafc;
    position: absolute;
    z-index: -1;
    top: -100vh;
    left: -100px;
    width: 150vw;
    height: 150vh;
    border-radius: 0 280px;
    transform: rotate(-22deg);
  }
}
</style>
