<template>
  <div class="home-about" id="homeAbout">
    <ul class="ec-container home-about-inner">
      <li class="home-about-left">
        <h2 class="home-about-title">关于我们</h2>
        <p>
          人翼咨询是简核（北京）企业管理咨询有限公司的HR管理咨询品牌，为企业提供专业
          的全决策链人力资源管理咨询服务。
        </p>
        <p>
          人翼咨询与多家HR
          Saas平台建立了长期战略合作关系，为企业提供专业的系统前人
          力资源管理体系全面梳理与优化服务。
        </p>
        <p></p>
        咨询方案聚焦人力资源规划、管控与组织设计、职位与职级体系、薪酬体系、绩效体
        系、中长期激励体系以及人才管理体系设计。
      </li>
      <li class="home-about-right">
        <img src="@/assets/images/home/aboutus-bg.png" alt="" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HomeAbout",
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.home-about {
  &-inner {
    padding-top: 79px;
    padding-bottom: 110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 102px;
  }
  &-title {
    font-size: 36px;
    font-weight: 400;
    color: #2f2f2f;
  }
  &-left {
    font-size: 16px;
    font-weight: normal;
    color: #525966;
    line-height: 31px;

    p {
      margin-top: 44px;
    }
  }
  &-right {
    width: 552px;

    img {
      display: block;
      width: 100%;
      border: 0;
    }
  }
}
</style>
