<template>
  <div class="home-footer">
    <div class="ec-container home-footer-inner">
      <div class="home-footer-top">
        <div class="home-footer-title">
          <img
            class="home-footer-logo"
            src="@/assets/images/logo2.png"
            alt=""
          />
          <div class="home-footer-400">
            <img
              src="@/assets/images/home/tell.png"
              alt=""
              class="home-footer-400-icon"
            />
            <div class="home-footer-400-profile">
              <h3 class="home-footer-400-title">全国咨询热线</h3>
              <p class="home-footer-400-number">40017-58858</p>
            </div>
          </div>
        </div>
        <div class="home-footer-contact">
          <h3 class="home-footer-contact-title">联系方式</h3>
          <ul class="home-footer-contact-list">
            <li class="home-footer-contact-item">
              <img
                src="@/assets/images/home/telephone.png"
                alt=""
                class="home-footer-contact-icon"
              />
              <div class="home-footer-contact-value">40017-58858</div>
            </li>
            <li class="home-footer-contact-item">
              <img
                src="@/assets/images/home/phone.png"
                alt=""
                class="home-footer-contact-icon"
              />
              <div class="home-footer-contact-value">15801116265</div>
            </li>
            <li class="home-footer-contact-item">
              <img
                src="@/assets/images/home/mail.png"
                alt=""
                class="home-footer-contact-icon"
              />
              <div class="home-footer-contact-value">info@51echeck.com</div>
            </li>
            <li class="home-footer-contact-item">
              <img
                src="@/assets/images/home/adress.png"
                alt=""
                class="home-footer-contact-icon"
              />
              <div class="home-footer-contact-value">
                北京市朝阳区建国门外永安东里甲3号通用国际中心A座1606
              </div>
            </li>
          </ul>
        </div>
        <div class="home-footer-wechat">
          <h3 class="home-footer-wechat-title">关注公众号</h3>
          <img
            class="home-footer-wechat-qrcode"
            src="@/assets/images/home/code.png"
            alt=""
          />
        </div>
      </div>
      <div class="home-footer-bot">
        Copyright &copy; 2020 人翼咨询 All rights reserved.
        <a target="_blank" href="https://beian.miit.gov.cn">
          京ICP备2020043064号
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeFooter",
  components: {},
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.home-footer {
  &-inner {
    padding-top: 223px;
  }
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
  }
  &-bot {
    margin-top: 63px;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    color: #ababab;
    padding: 28px 0 32px;
    border-top: 1px solid #f0f0f0;

    a {
      color: #ababab;
    }
  }
  &-title {
  }
  &-contact {
    margin-left: 367px;
  }
  &-wechat {
    margin-left: 105px;
  }
  &-logo {
    height: 39px;
  }
  &-400 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    margin-top: 81px;
  }
  &-400-profile {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }
  &-400-icon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    flex-grow: 0;
  }
  &-400-title {
    font-size: 13px;
    font-weight: 400;
    color: #ababad;
  }
  &-400-number {
    font-size: 25px;
    font-weight: bold;
    color: @ec-color-primary;
    line-height: 1;
  }
  &-contact-title {
    font-size: 24px;
    font-weight: bold;
    color: #3e3e3e;
  }
  &-contact-list {
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 21px;
  }
  &-contact-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    flex-wrap: nowrap;
  }
  &-contact-icon {
    width: 20px;
    height: 20px;
  }
  &-contact-value {
    font-size: 14px;
    font-weight: 400;
    color: #686868;
    white-space: nowrap;
  }
  &-wechat-title {
    font-size: 24px;
    font-weight: bold;
    color: #3e3e3e;
  }
  &-wechat-qrcode {
    width: 146px;
    height: 146px;
    margin-top: 30px;
  }
}
</style>
