import { message } from "ant-design-vue";

/**
 * 展示消息
 * @param {Object} options
 * @param {0|1|2} options.code
 * @param {string} options.msg
 */
export function showToast(options) {
  return new Promise((resolve) => {
    const { code, msg } = options;
    if (code === 0) {
      message.success(msg).then(() => {
        resolve(true);
      });
      return;
    }
    if (code === 1) {
      message.warn(msg).then(() => {
        resolve(true);
      });
      return;
    }
    if (code === 2) {
      message.error(msg).then(() => {
        resolve(true);
      });
      return;
    }
    resolve(null);
  });
}
