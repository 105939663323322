<template>
  <button type="button" class="ec-btn">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "EcButton",
};
</script>

<style scoped lang="less">
.ec-btn {
  height: 46px;
  line-height: 46px;
  outline: none;
  border: 0;
  text-align: center;
  background: @ec-color-primary;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  padding: 0 39px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s linear;

  &:focus {
    background: darken(@ec-color-primary, 10%);
  }
  &:hover {
    background: darken(@ec-color-primary, 5%);
  }
}
</style>
